.product-card-div {
  color: rgb(88, 96, 105);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  cursor: pointer;
  border-radius: 2px;
  min-height: 250px;
  max-height: 500px;
  width: 300px;
  margin-bottom: 20px;
  max-width: 300px;
  border-color: #F5F5F5;
  border-width: 1.5px;
  border-style: solid;
  display: inline-block;
}

.product-card-div:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.product-image-div {
  display: block;
  align-items: center;
  text-align: center;
  max-width: 100%;
  width: 100%;
  height: 150px;
  overflow: hidden
}

.img-card {
  max-width: 100%;
  max-height: 100%;
  border-radius: 2px 2px 0 0;
  padding-top: 10px;
}

.product-name-div {
  display: flex;
  align-items: center;
}

.product-name {
  white-space: nowrap;
  padding-left: 15px;
  padding-top: 12px;
  text-overflow: ellipsis;
  color: rgb(36, 41, 46);
  font-family: "Google Sans Regular";
  margin-bottom: 0.75rem;
  font-size: 17px;
  font-weight: 700;
  letter-spacing: -0.5px;
  overflow: hidden;
  margin: 0px;
  text-align: center;
}

.product-description {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  word-wrap: break-word;
  text-align: center;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  font-family: "Google Sans Regular";
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.product-details {
  justify-content: flex-end;
  display: flex;
  padding-bottom: 3px;
  justify-content: center;
}

@media (max-width: 768px) {

  .product-card-div {
    color: rgb(88, 96, 105);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    cursor: pointer;
    border-radius: 2px;
    min-height: 250px;
    max-height: 400px;
    width: 300px;
    margin-bottom: 20px;
    max-width: 300px;
    border-color: #F5F5F5;
    border-width: 1.5px;
    border-style: solid;
  }

  .product-name {
    font-size: 16px;
  }

  .product-description {
    font-size: 14px;
    margin-bottom: -100px;
    
  }

  .product-details {
    justify-content: flex-end;
    display: flex;
    justify-content: center;
    padding-top: 82px;
  }
}