input[type="text"] {
  width: 100%;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  margin-bottom: 18px;
  margin-left: 20px;
  box-sizing: border-box;
  transition: 0.3s;
  width: 500px;
}

textarea {
  width: 100%;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  margin-bottom: 20px;
  margin-left: 20px;
  box-sizing: border-box;
  transition: 0.3s;
  width: 500px;
}

input[type="text"]:focus {
  border-color: #c17171;
  box-shadow: 0 0 8px 0 #c17171;
}

textarea:focus {
  border-color: #c17171;
  box-shadow: 0 0 8px 0 #c17171;
}

.errorClass {
  text-align: center;
  color: red;
  font-size: 12px;
}

.product-main {
  width: 100vw;
}

.basic-contact {
  margin-left: 5%;
  margin-right: 5%;
}

.product-heading-div {
  display: flex;
}

.blog-heading-div {
  display: flex;
}

.address-heading-div {
  display: flex;
  margin-top: 80px;
}

.blog-heading-div > * {
  flex: 1;
}

.product-heading-div > * {
  flex: 1;
}

.address-heading-div > * {
  flex: 1;
}

.product-heading-form-div {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  border-radius: 5px;
}

.product-heading-form-div:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.blog-heading-form-div {
  align-items: center;
  justify-content: center;
}

.product-heading-text-div {
  text-align: center;
  margin-top: 100px;
}

.blog-heading-text-div {
  text-align: center;
}

.address-heading-text-div {
  text-align: center;
}

.product-heading-form-div > * {
  max-width: 100%;
  height: auto;
}

.blog-heading-form-div > * {
  max-width: 100%;
  height: auto;
}

.product-heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 50px;
}

.blog-heading-text {
  font-size: 56px;
  font-weight: 400;
  font-family: "Google Sans Medium";
  line-height: 1.1;
  text-align: center;
  margin-top: 40px;
}

.address-heading-text {
  font-size: 30px;
  font-family: "Google Sans Regular";
  text-align: center;
  margin-bottom: 10px;
}

.product-header-detail-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Google Sans Medium";
}

.product-header-form-head-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-bottom: 30px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Google Sans Medium";
}

.product-header-form-detail-text {
  color: #35363a;
  font-size: 18px;
  line-height: 2px;
  margin-top: 10px;
  line-height: 25px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Google Sans Medium";
  text-align: left;
}

.product-header-detail-text-message {
  height: 70px;
}

.blog-header-detail-text {
  font-size: 20px;
  line-height: 30px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  font-family: "Google Sans Medium";
}

.resume-btn-div-product {
  width: 100%;
  display: flex;
  justify-content: center;
}

.blogsite-btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.address-btn-div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0;
}

@media (max-width: 1380px) {
  .product-heading-text {
    font-size: 50px;
    margin-top: 60px;
  }

  .blog-heading-text {
    font-size: 50px;
    margin-top: 40px;
  }
}

@media (max-width: 768px) {
  input[type="text"] {
    width: 100%;
    border: 2px solid #aaa;
    border-radius: 4px;
    margin: 8px 0;
    outline: none;
    padding: 8px;
    margin-bottom: 20px;
    margin-left: 20px;
    box-sizing: border-box;
    transition: 0.3s;
    width: 280px;
  }

  input[type="text"]:focus {
    border-color: #c17171;
    box-shadow: 0 0 8px 0 #c17171;
  }
  .product-heading-text {
    font-size: 30px;
    margin-top: 0px;
  }

  .blog-heading-text {
    font-size: 30px;
    margin-top: 0px;
  }

  .address-heading-text {
    font-size: 20px;
  }

  .blog-heading-div {
    flex-direction: column-reverse;
    margin-top: 60px;
  }

  .address-heading-div {
    flex-direction: column;
    margin-top: 60px;
  }

  .blog-heading-text-div {
    margin-top: 40px;
  }

  .address-heading-text-div {
    margin-top: 40px;
  }

  .product-heading-div {
    flex-direction: column;
  }

  .product-header-detail-text {
    font-size: 16px;
    margin-left: 0px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }

  .blog-header-detail-text {
    font-size: 16px;
    margin-left: 0px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }
}
