.repo-card-div {
  color: rgb(88, 96, 105);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  background: #000000;
  padding: 1.5rem;
  cursor: pointer;
  border-radius: 2px;
  height: 225px;
  width: 225px;
  margin-bottom: 20px;
  position: relative;
}

.repo-card-div:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.6;
  background-image: url("../../assests/images/cement.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
}

.repo-card-div:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.repo2-card-div {
  color: rgb(88, 96, 105);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  background: #000000;
  padding: 1.5rem;
  cursor: pointer;
  border-radius: 2px;
  height: 225px;
  width: 225px;
  margin-bottom: 20px;
  position: relative;
}

.repo2-card-div:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.6;
  background-image: url("../../assests/images/power.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
}

.repo2-card-div:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.repo3-card-div {
  color: rgb(88, 96, 105);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  background: #000000;
  padding: 1.5rem;
  cursor: pointer;
  border-radius: 2px;
  height: 225px;
  width: 225px;
  margin-bottom: 20px;
  position: relative;
}

.repo3-card-div:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.6;
  background-image: url("../../assests/images/ind.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
}

.repo3-card-div:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.repo4-card-div {
  color: rgb(88, 96, 105);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  background: #000000;
  padding: 1.5rem;
  cursor: pointer;
  border-radius: 2px;
  height: 225px;
  width: 225px;
  margin-bottom: 20px;
  position: relative;
}

.repo4-card-div:before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.6;
  background-image: url("../../assests/images/marine.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
}

.repo4-card-div:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.repo-name-div {
  display: flex;
  height: 100%;
  align-items: center;
  z-index: 2;
  position: relative;
}

.repo-name {
  text-align: center;
  width: 100%;
  color: rgb(36, 41, 46);
  font-family: "Google Sans Regular";
  font-size: 25px;
  line-height: normal;
  font-weight: 700;
  overflow: hidden;
}

.repo-description {
  text-align: center;
  word-wrap: break-word;
  text-align: left;
  font-size: 15px;
  display: -webkit-box;
  font-family: "Google Sans Regular";
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.repo-details {
  display: flex;
  flex-direction: row;
}

@media (max-width: 768px) {
  .repo-name {
    font-size: 25px;
  }

  .repo-description {
    font-size: 14px;
  }

  .repo-details {
    flex-direction: column;
  }
}
