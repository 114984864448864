.skills-main-div {
  display: flex;
}

.skills-header {
  font-size: 60px;
  line-height: 1.1;
  font-family: "Google Sans Regular";
  text-align: center;
}

.skills-text {
  font-size: 20px;
}

.skills-text-div {
  margin-left: 50px;
  font-family: "Google Sans Regular";
}

.resume-btn-div-work {
  width: 100%;
  display: flex;
  justify-content: center;
}

.skills-main-div > * {
  flex: 1;
  margin-bottom: 30px;
}

.skills-image-div > * {
  max-width: 100%;
  height: auto;
  margin-top: 100px;
}

.skills-heading {
  font-size: 50px;
  font-weight: 400;
  text-align: center;
  padding-bottom: 10px;
  font-family: "Google Sans Regular";
}

.repo-cards-div-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1rem 1rem;
}

.flex-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.flex-container2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

.flex-container > div {
  margin: 20px;
}

/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 40px;
  }
  .skills-header {
    font-size: 50px;
  }
  /* .skills-image-div > img {
    margin-top: 0px;
  } */
}
@media (max-width: 768px) {
  .flex-container {
    display: grid;
    flex-flow: column;
    justify-content: center;
  }

  .flex-container > div {
    margin: 6px;
  }

  .skills-heading {
    font-size: 30px;
    text-align: center;
  }
  .skills-header {
    font-size: 30px;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
  }
  .skills-main-div {
    flex-direction: column;
  }
  .skills-text-div {
    margin-left: 0px;
    margin: 20px;
  }
  .skills-text {
    font-size: 16px;
  }
  .skills-text-subtitle {
    font-size: 16px;
    text-align: center;
  }
  .skills-image-div {
    /* display: none; */
    order: 2;
  }
  .skills-image-div > * {
    margin-top: 0px;
  }
}

.service {
  background: var(--clr-white);
  padding: 3rem 1.5rem;
  margin-bottom: 2rem;
  border-radius: var(--radius);
  text-align: center;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.service-icon {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}
.service .underline {
  width: 3rem;
  height: 0.12rem;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.service p {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}
.service:hover {
  background: var(--clr-primary-5);
  color: var(--clr-primary-9);
}
.service:hover p {
  color: var(--clr-primary-9);
}
.service:hover .underline {
  background: var(--clr-primary-9);
}

.section-center {
  width: 90vw;
  margin: 0 auto;
  max-width: 1170px;
}

@media screen and (min-width: 992px) {
  .section-center {
    width: 95vw;
  }
}

@media screen and (min-width: 676px) {
  .services-center {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -webkit-column-gap: 2rem;
    -moz-column-gap: 2rem;
    column-gap: 2rem;
  }
}
@media screen and (min-width: 992px) {
  .services-center {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .repo-cards-div-main {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}
