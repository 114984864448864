.footer-text {
  text-align: center;
  /* color: #868e96; */
  font-size: 18px;
  font-weight: bold;
  font-family: "Google Sans Regular";
}

.footer-div {
  margin-top: 2rem;
}
