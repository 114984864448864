.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem;
}

.show-image {
  position: relative;
  width: 100%; /* for IE 6 */

  margin-top: 105px;
}

.show-text {
  font-family: "Google Sans Regular";
  font-size: 35px;
  line-height: normal;
  position: absolute;
  top: 80%;
  left: 50%;
  padding: 5px;
  margin-left: -130px;
  text-align: center;
}

.show-text2 {
  color: gray;
  font-size: 21px;
  margin-top: 3px;
}

.greet-main {
  width: auto;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 1.25rem;
}

.greeting-main {
  display: flex;
  padding-left: 50px;
  padding-right: 50px;
}

.greeting-main > * {
  flex: 1;
}

.button-greeting-div {
  display: flex;
  margin-top: 20px;
}

.greeting-text {
  margin-top: 150px;
  font-size: 70px;
  line-height: 0;
  font-family: "Google Sans Bold";
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  margin-top: 20px;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-top: -10px;
}

.greeting-text-p {
  font-size: 21px;
  line-height: 30px;
  margin-right: 40px;
  font-family: "Google Sans Medium";
}

.greeting-image-div > * {
  max-width: 100%;
  height: auto;
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .show-image {
    position: relative;
    width: 100%; /* for IE 6 */
    margin-top: 70px;
  }

  .show-text2 {
    color: gray;
    font-size: 19px;
    margin-top: 3px;
  }

  .button-greeting-div {
    justify-content: space-around;
  }

  .greeting-text {
    font-size: 30px;
    margin-top: 0px;
    text-align: center;
  }
  .greeting-nickname {
    font-size: 25px;
    text-align: center;
  }

  .greeting-text-p {
    font-size: 16px;
    margin-right: 0px;
    line-height: normal;
    text-align: center;
  }

  .greeting-main {
    display: block;
    width: 500px;
  }

  .show-text {
    font-family: "Google Sans Regular";
    text-align: center;
    font-size: 22px;
    line-height: normal;
    position: absolute;
    margin-left: 0px;
    top: 80%;
    left: 0;
    right: 0;
  }

  .portfolio-repo-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .greet-main {
    width: 300px;
    padding: 20px 10px;
    margin: 0px auto;
  }

  .center {
    display: block;
    /* margin-left: auto;
    margin-right: auto; */
    margin-top: -6px;
    width: 100%;
    height: 100%;
  }
}
