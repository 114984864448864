.partner-card-div {
  display: grid;
  grid-template-columns: 1fr repeat(6, minmax(auto, 10em)) 1fr;
  grid-template-rows: minmax(1em, auto) 1fr auto minmax(1em, auto);
  color: rgb(88, 96, 105);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
  cursor: pointer;
  border-radius: 2px;
  height: 205px;
  width: 510px;
  margin-bottom: 20px;
}
.partner-card-div:hover {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px -10px;
}

.logo-name {
  grid-column: 2;
  background-color: #ffe7e7;
  height: 205px;
  width: 200px;
  padding: 20px;
}

.partner-info {
  grid-column: 3;
  background-color: #ffd4d4;
  height: 205px;
  width: 310px;
  padding-left: 10px;
  padding-bottom: 20px;
}

.partner-name-div {
  display: flex;
  align-items: center;
}

.partner-name {
  display: flex;
  white-space: normal;
  text-overflow: ellipsis;
  color: rgb(36, 41, 46);
  font-family: "Google Sans Regular";
  font-size: 15px;
  font-weight: 200;
  letter-spacing: -0.3px;
  overflow: hidden;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: -5px;
}

.partner-link {
  font-size: 15px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  color: #3196e4;
}

.partner-link:visited {
  color: #86c0eb;
}

@media (max-width: 768px) {
  .partner-card-div {
    color: rgb(88, 96, 105);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    cursor: pointer;
    border-radius: 2px;
    height: 200px;
    width: 300px;
    margin-bottom: 20px;
  }

  .logo-name {
    grid-column: 2;
    background-color: #ffe7e7;
    height: 210px;
    width: 110px;
    padding: 20px;
  }

  .partner-info {
    grid-column: 3;
    background-color: #ffd4d4;
    height: 210px;
    width: 190px;
  }

  .partner-name {
    font-size: 12.5px;
    padding-top: 5px;
  }

  .partner-link {
    font-size: 12.5px;
  }
}
